import { ACCOUNT_TYPE_BUSINESS, ACCOUNT_TYPE_WORKER } from '../util/types';

// Details
const detailsBusiness = new Set();
detailsBusiness.add('industry');
const detailsWorker = new Set();
detailsWorker.add('industry');

// Skills
const skillsBusiness = new Set();
skillsBusiness.add('skills_required');
skillsBusiness.add('language_required');
const skillsWorker = new Set();
skillsWorker.add('skills');
skillsWorker.add('language_skills');

// Health & Safety
const healthBusiness = new Set();
healthBusiness.add('safety_environment_requirements');
healthBusiness.add('dress_code_instructions');
const healthWorker = new Set();
healthWorker.add('ongoing_health_concerns');

// Location
const locationBusiness = new Set();
locationBusiness.add('location_instructions');
locationBusiness.add('parking');
const locationWorker = new Set();

// Contact
const contactBusiness = new Set();
const contactWorker = new Set();

// More Info
const moreInfoBusiness = new Set();
moreInfoBusiness.add('staff_meal');
moreInfoBusiness.add('other_instructions');
const moreInfoWorker = new Set();
moreInfoWorker.add('special_notes');

// Time
const timeBusiness = new Set();
timeBusiness.add('unpaid_break');
timeBusiness.add('included_paid_break_time');
const timeWorker = new Set();

export const configData = {
  editListingWizard: {
    details: {
      [ACCOUNT_TYPE_BUSINESS]: detailsBusiness,
      [ACCOUNT_TYPE_WORKER]: detailsWorker,
    },
    skills: {
      [ACCOUNT_TYPE_BUSINESS]: skillsBusiness,
      [ACCOUNT_TYPE_WORKER]: skillsWorker,
    },
    health: {
      [ACCOUNT_TYPE_BUSINESS]: healthBusiness,
      [ACCOUNT_TYPE_WORKER]: healthWorker,
    },
    location: {
      [ACCOUNT_TYPE_BUSINESS]: locationBusiness,
      [ACCOUNT_TYPE_WORKER]: locationWorker,
    },
    contact: {
      [ACCOUNT_TYPE_BUSINESS]: contactBusiness,
      [ACCOUNT_TYPE_WORKER]: contactWorker,
    },
    moreInfo: {
      [ACCOUNT_TYPE_BUSINESS]: moreInfoBusiness,
      [ACCOUNT_TYPE_WORKER]: moreInfoWorker,
    },
    time: {
      [ACCOUNT_TYPE_BUSINESS]: timeBusiness,
      [ACCOUNT_TYPE_WORKER]: timeWorker,
    },
  },
};
