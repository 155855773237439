import React, { useState } from 'react';
import { arrayOf, bool, func, object, string } from 'prop-types';
import { compose } from 'redux';
import classNames from 'classnames';
import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import { ACCOUNT_TYPE_BUSINESS, ACCOUNT_TYPE_WORKER, propTypes } from '../../../util/types';
import { ensureListing, ensureUser } from '../../../util/data';
import { Avatar } from '../../../components';
import css from './SearchMapInfoCard.module.scss';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// ListingCard is the listing info without overlayview or carousel controls
const ListingCard = props => {
  const { className, clickHandler, isInCarousel, listing, urlToListing, config } = props;

  const { title } = listing.attributes;

  const author = ensureUser(listing.author);
  const authorName = author.attributes.profile.displayName;
  const firstImage = listing.images && listing.images.length > 0 ? listing.images[0] : null;

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
    : [];

  // listing card anchor needs sometimes inherited border radius.
  const classes = classNames(
    css.anchor,
    css.borderRadiusInheritTop,
    { [css.borderRadiusInheritBottom]: !isInCarousel },
    className
  );

  const userPublicData = author?.attributes?.profile?.publicData;
  const profileAccountType = userPublicData?.profileAccountType;
  const businessName = userPublicData?.businessName || null;
  const departmentNumber = userPublicData?.departmentNumber;
  const locationNumber = userPublicData?.locationNumber;
  const createdAt = author.attributes.createdAt;
  const isBusiness = profileAccountType === ACCOUNT_TYPE_BUSINESS;
  const isWorker = profileAccountType === ACCOUNT_TYPE_WORKER;
  const memberSince = createdAt ? moment(createdAt).format('MMM YYYY') : null;
  const listingDisplayName = isBusiness ? businessName : authorName;

  console.log(`InfoWindow author`, author);

  return (
    <a
      alt={title}
      className={classes}
      href={urlToListing}
      onClick={e => {
        e.preventDefault();
        // Use clickHandler from props to call internal router
        clickHandler(listing);
      }}
    >
      <div
        className={classNames(css.card, css.borderRadiusInheritTop, {
          [css.borderRadiusInheritBottom]: !isInCarousel,
        })}
      >
        <div className={css.rowUnsetMarginLR}>
          <div className={css.info}>
            <Avatar className={css.avatar} user={author} disableProfileLink={true} />
            <div className={css.col8}>
              <div className={css.mainInfo}>
                <div className={css.authorInfo}>
                  <FormattedMessage
                    id="ListingCard.author"
                    values={{ authorName: listingDisplayName }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={css.rowUnsetMarginLR}>
          <div className={css.col12}>
            <div className={css.name}>{title}</div>
          </div>
          <div className={css.col12}>
            <div className={css.dataAttrSection}>
              {profileAccountType && (
                <div className={css.dataAttr}>
                  <strong>
                    <FontAwesomeIcon className={css.icon} icon={'fa-solid fa-user'} />
                    <FormattedMessage id="ListingCard.profileAccountType" />{' '}
                  </strong>
                  {isBusiness && <FormattedMessage id="SignupForm.profileAccountType.business" />}
                  {isWorker && <FormattedMessage id="SignupForm.profileAccountType.worker" />}
                </div>
              )}
              {isWorker && memberSince && (
                <div className={css.dataAttr}>
                  <strong>
                    <FontAwesomeIcon className={css.icon} icon={'fa-solid fa-calendar'} />
                    <FormattedMessage id="ListingCard.memberSince" />{' '}
                  </strong>
                  {memberSince}
                </div>
              )}
              {isBusiness && locationNumber && (
                <div className={css.dataAttr}>
                  <strong>
                    <FontAwesomeIcon className={css.icon} icon={'fa-solid fa-location-dot'} />
                    <FormattedMessage id="ListingCard.locationNumber" />{' '}
                  </strong>
                  {locationNumber}
                </div>
              )}
              {isBusiness && departmentNumber && (
                <div className={css.dataAttr}>
                  <strong>
                    <FontAwesomeIcon className={css.icon} icon={'fa-solid fa-building'} />
                    <FormattedMessage id="ListingCard.departmentNumber" />{' '}
                  </strong>
                  {departmentNumber}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </a>
  );
};

ListingCard.defaultProps = {
  className: null,
};

ListingCard.propTypes = {
  className: string,
  listing: propTypes.listing.isRequired,
  clickHandler: func.isRequired,
  intl: intlShape.isRequired,
  isInCarousel: bool.isRequired,
};

const SearchMapInfoCard = props => {
  const [currentListingIndex, setCurrentListingIndex] = useState(0);
  const {
    className,
    rootClassName,
    intl,
    listings,
    createURLToListing,
    onListingInfoCardClicked,
    config,
  } = props;
  const currentListing = ensureListing(listings[currentListingIndex]);
  const hasCarousel = listings.length > 1;

  const classes = classNames(rootClassName || css.root, className);
  const caretClass = classNames(css.caret, { [css.caretWithCarousel]: hasCarousel });

  return (
    <div className={classes}>
      <div className={css.caretShadow} />
      <ListingCard
        clickHandler={onListingInfoCardClicked}
        urlToListing={createURLToListing(currentListing)}
        listing={currentListing}
        intl={intl}
        isInCarousel={hasCarousel}
        config={config}
      />
      {hasCarousel ? (
        <div className={classNames(css.paginationInfo, css.borderRadiusInheritBottom)}>
          <button
            className={css.paginationPrev}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              setCurrentListingIndex(
                prevListingIndex => (prevListingIndex + listings.length - 1) % listings.length
              );
            }}
          />
          <div className={css.paginationPage}>
            {`${currentListingIndex + 1}/${listings.length}`}
          </div>
          <button
            className={css.paginationNext}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              setCurrentListingIndex(
                prevListingIndex => (prevListingIndex + listings.length + 1) % listings.length
              );
            }}
          />
        </div>
      ) : null}
      <div className={caretClass} />
    </div>
  );
};

SearchMapInfoCard.defaultProps = {
  className: null,
  rootClassName: null,
};

SearchMapInfoCard.propTypes = {
  className: string,
  rootClassName: string,
  listings: arrayOf(propTypes.listing).isRequired,
  onListingInfoCardClicked: func.isRequired,
  createURLToListing: func.isRequired,
  config: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default compose(injectIntl)(SearchMapInfoCard);
