import { ACCOUNT_TYPE_AGENT, ACCOUNT_TYPE_BUSINESS, ACCOUNT_TYPE_WORKER } from './types';

export const validateProfileAccountType = profileAccountType => {
  if (
    profileAccountType === ACCOUNT_TYPE_BUSINESS ||
    profileAccountType === ACCOUNT_TYPE_WORKER ||
    profileAccountType === ACCOUNT_TYPE_AGENT
  ) {
    return true;
  } else {
    return false;
  }
};
