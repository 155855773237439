import { ACCOUNT_TYPE_BUSINESS, ACCOUNT_TYPE_WORKER } from '../util/types';
import { configData } from './configData';
const { editListingWizard } = configData;

const businessSet = new Set();
for (const tab in editListingWizard) {
  const entries = Array.from(editListingWizard[tab][ACCOUNT_TYPE_BUSINESS]);
  for (let i = 0; i < entries.length; i++) {
    businessSet.add(entries[i]);
  }
}

const workerSet = new Set();
for (const tab in editListingWizard) {
  const entries = Array.from(editListingWizard[tab][ACCOUNT_TYPE_WORKER]);
  for (let i = 0; i < entries.length; i++) {
    workerSet.add(entries[i]);
  }
}

const configListingData = {
  [ACCOUNT_TYPE_BUSINESS]: businessSet,
  [ACCOUNT_TYPE_WORKER]: workerSet,
};

export default configListingData;
